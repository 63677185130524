import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/user';

const UserContext = createContext();
export default UserContext;

export function UserProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		paidAccessCallout: null
	});

	return (<UserContext.Provider value={{ data, dispatch }}>{children}</UserContext.Provider>);
}

UserProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(UserContext);
