import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'profile>submenu_cnt'
	},

	data: null,

	gql: {
		schema: gql`
			query ProfileSubmenuCnt {
				request {
					items_saved_cnt
			    items_owned_cnt
			    filters_cnt
				}
			}
		`
	}
};

export default request;
