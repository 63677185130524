import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './modal.module.scss';
import AsyncComponent from '../_hoc/asyncComponent';

const Modal = ({ zIndex, component, visible, onClose, props }) => {
	const [vsbl, setVisible] = useState(visible);
	const Component = useRef(null);

	const loadComponent = () => {
		Component.current = AsyncComponent(() => {
			switch (component) {
			case 'detail/notifications': 	return import('./detail/notifications');
			case 'detail/etn-detail': 		return import('./detail/etn-detail');
			case 'auth/quickView': 				return import('./auth/quickView');
			case 'webshop/payments': 			return import('./webshop/payments');
			case 'prompt': 								return import('./prompt/prompt');
			case 'system/updater': 				return import('./system/updater');
			case 'user/paid-access': 			return import('./user/paid-access');
			default: return null;
			}
		});
	};

	// On load
	if (Component.current === null && component) {
		loadComponent(component);
	}

	useEffect(() => {
		setVisible(visible);
	}, [visible]);

	return (
		<div className={`${styles.default} ${vsbl ? styles.visible : ''}`} style={{ zIndex }}>
			<div className={styles.aligned}>{Component.current && <Component.current onClose={(d) => {
				setVisible(!vsbl);
				if (onClose) onClose(d);
			}} {...props} />}</div>
		</div>
	);
};

Modal.defaultProps = {
	visible: false,
	props: {},
	onClose: null,
	zIndex: 1000
};

Modal.propTypes = {
	component: PropTypes.string.isRequired,
	visible: PropTypes.bool,
	props: PropTypes.shape({}),
	onClose: PropTypes.func,
	zIndex: PropTypes.number
};

export default Modal;
