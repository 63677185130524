import { useEffect } from 'react';

import useLanguage from './useLanguage';
import useAuth from './useAuth';
import useAPI from './useAPI';
import { useStore as profileStore } from '../../contexts/profile';
import requestSubmenuCnt from '../../requests/query/profile/submenu-cnt';

const useProfile = () => {
	const { data: { navigation }, dispatch } = profileStore();
	const { isLanguageLoaded, t } = useLanguage();
	const { userData } = useAuth();
	const { query } = useAPI();

	useEffect(() => {
		if (navigation === null && isLanguageLoaded) {
			dispatch({
				type: 'SET_NAVIGATION',
				payload: {
					name: 'settings',
					list: [
						{ href: '/profil', text: t('labelAccount') },
						userData.oauth_id ? null : { href: '/profil/uporabnik', text: t('labelAccountProfile') },
						{ href: '/profil/obvestila-nastavitve', text: t('labelAccountNotifications') },
						{ href: '/profil/placila', text: t('labelAccountPayments') }
					]
				}
			});
		}
	}, [isLanguageLoaded]);

	const getSubmenutCnt = (callback) => {
		query(requestSubmenuCnt, {}, callback, { fetchPolicy: 'cache-first' });
	};

	return { navigation, getSubmenutCnt };
};

export default useProfile;
