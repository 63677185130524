import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'notification>items'
	},

	data: null,

	gql: {
		schema: gql`
			query NotificationItems(
			  $limit: [Int],
				$isread: Int
			) {
				request(
			    limit: $limit,
					isread: $isread
				) {
			    list {
						log_notification_id
			     	isread
			      subject
			      bodytext
			      created_dtm
			    }
			    pageInfo {
			      total_cnt
			      unread_cnt
			    }
				}
			}
		`
	}
};

export default request;
