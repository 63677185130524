import requestGet from '../../requests/query/notification/items';
import requestSetAsRead from '../../requests/mutation/notification/mutate';
import useAPI from './useAPI';
import useAuth from './useAuth';
import useApp from './useApp';

const useNotification = () => {
	const { query, mutate } = useAPI();
	const { authenticated } = useAuth();
	const { appData: { notificationsUnreadList, notificationIsRead, notificationIsVisible }, appDispatch } = useApp();

	const setUnreadList = (list) => {
		appDispatch({ type: 'SET_NOTIFICATIONS_UNREAD', payload: list });
	};

	const loadNotifications = (variables, callback) => {
		if (authenticated) {
			return query(requestGet, variables, callback);
		}

		callback(null);
		return null;
	};

	const setAllUnreadAsRead = (clearList = true) => {
		if (notificationsUnreadList && notificationsUnreadList.length > 0) {
			const l = [];
			notificationsUnreadList.map(row => l.push(row.log_notification_id));

			setAsRead({ log_notification_id: l }, (d, e) => {
				if (d) {
					appDispatch({ type: 'SET_NOTIFICATIONS_ISREAD' });

					if (clearList === true) {
						appDispatch({ type: 'SET_NOTIFICATIONS_UNREAD', payload: [] });
					}
				}
				// if (e) console.log('ERROR ON SET ALL NOTIFICATIONS AS READ', e);
			});
		}
	};

	const toggleNotifications = () => {
		appDispatch({ type: 'TOGGLE_NOTIFICATIONS' });
	};

	const setAsRead = (variables, callback) => {
		if (authenticated) {
			return mutate(requestSetAsRead, { ...variables, isread: 1 }, callback);
		}

		callback(null);
		return null;
	};

	return { loadNotifications, toggleNotifications, setAsRead, setAllUnreadAsRead, setUnreadList, notificationsUnreadList, notificationIsRead, notificationIsVisible };
};

export default useNotification;
